module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"myriad","defaultLang":"en-us","accessToken":"MC5YMzJDX2hVQUFDZ0FpNUph.Oe-_vThV77-9QGPvv73vv73vv71-77-977-977-977-977-977-977-977-977-977-977-9N--_vXZ-77-9RO-_vShIMQ","path":"/preview","previews":true,"omitPrismicScript":false,"sharpKeys":[{}],"pages":[{"type":"generic_page","match":"/:uid","path":"/generic","component":"/opt/build/repo/src/templates/generic.js"},{"type":"project_page","match":"/work/:uid","path":"/work","component":"/opt/build/repo/src/templates/project.js"},{"type":"original_page","match":"/originals/:uid","path":"/originals","component":"/opt/build/repo/src/templates/original.js"},{"type":"blog_post","match":"/blog/:uid","path":"/blog","component":"/opt/build/repo/src/templates/blog-post.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"injectPageProps":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Myriad","short_name":"Myriad","start_url":"/","background_color":"#fff","display":"minimal-ui","icon":"src/assets/favicon/favicon.png","include_favicon":true,"cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PM3VC3P","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://myriad.video/","stripQueryString":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
